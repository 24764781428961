import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import baseURL from "../Environment";
import OtpInput from "./OtpInput";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Make sure to import the styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHand } from '@fortawesome/free-solid-svg-icons';
import './Login.css';
export default function Login() {
    // Define state variables
    const [phoneNumber, setPhoneNumber] = useState(""); // Stores the phone number entered by the user
    const [showOtpInput, setShowOtpInput] = useState(false); // Controls whether to show the OTP input field
    const [resentOtp, setResentOtp] = useState(false); // Tracks if the OTP has been resent
    const [showError, setShowError] = useState(false); // Controls whether to show the error message for phone number validation
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); // Tracks if the user is logged in

    // Function to handle the call button click
    const handleCall = () => {
      window.location.href = 'tel:+919458419999';
    };

      // Function to handle key press events while enter phone number
      const handleKeyPress = (e) => {
        // Prevent typing alphabets
        if (e.key.match(/[a-zA-Z]/)) {
          e.preventDefault();
        }
        setShowError(false); 
        if (e.key === 'Enter') {
          handlePhoneSubmit(e);
        }
      };

      const handlePhoneSubmit = async (event) => {
        event.preventDefault();
        if (!phoneNumber || phoneNumber.length < 13) {
          setShowError(true); // Show error if phone number is empty
          return;
        }
        // Prepare the data to be sent in the POST request
        const requestData = {
          phoneNumber: phoneNumber // Assuming phoneNumber is defined elsewhere in your code
        };
    
        try {
          const response = await fetch(baseURL + 'profile/send-otp', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          // If the request was successful, you can handle the response here
          // For example, you can check if the OTP was sent successfully
          const responseData = await response.json();
          // console.log(responseData); // Handle the response data as per your API's response format
        } catch (error) {
          console.error('There was a problem with your fetch operation:', error);
          // Handle errors here, such as displaying an error message to the user
        }
        setResentOtp(false);
        setShowOtpInput(true);
      };
      
      const handleResendOtp = (event) => {
        event.preventDefault();
        setResentOtp(true);
        handlePhoneSubmit(event); // Pass the event to handlePhoneSubmit
      };
      const handleGoogleSignIn = () => {
        window.open("https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fwww.google.com%2F%3Fptid%3D19027681%26ptt%3D8%26fpts%3D0&ec=futura_hpp_co_si_001_p&ifkv=ASKXGp3MtpfyCyvQnrduLFH5o9SO-FNxAdqnQY8YMqfevp3-kFJZAnAtIB1QLt8JSAnLrTjJxuJV4g&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S-1811037472%3A1706179039408278&theme=glif", "_blank");
      };
    

      
  return (
    <div className="space">
    <>
      <div className="fs-2 my-3 icon text-center">
        <FontAwesomeIcon icon={faHand} className="hand-icon" />
        <h4 className="fw-bold my-4 fs-4">WELCOME</h4>
        <h6 className="mb-2 fw-bold signin">SIGN IN or SIGN UP</h6>
      </div>
      <hr className="text-success horizon"/>
      <div className="form-data">
        {!showOtpInput ? (<form onSubmit={handlePhoneSubmit}>
          <p className="text-center otp my-4">Enter your phone number and we will send you a 6 digit OTP.</p>
          <PhoneInput
            international
            defaultCountry="IN"
            placeholder="Enter Phone Number"
            className="w-100 phonenumber-check2"
            value={phoneNumber}
            onChange={setPhoneNumber}
            onKeyPress={handleKeyPress}
            autoFocus
            maxLength='15' />
             {showError && <p className="error-message">Phone number is required.</p>} {/* Show error message */}
          <div className="mobileotp">
            <button className="btn btn-outline-success fw-bold mb-4">SEND OTP</button>
          </div>
        </form>) :
          (<div><p className="text-center otp my-4">We have sent a 6 digit OTP to {phoneNumber}. Please enter it here. <span className="resendotp" onClick={handleResendOtp}>Resend OTP?</span></p>
            <OtpInput phoneNumber={phoneNumber} length={6}  resentOtp={resentOtp}  />
          </div>)
        }
      </div>
      <div className="container">
        <div className="row">
          <div className="text-center my-5">
            <img src="https://cdn-icons-png.flaticon.com/512/7589/7589521.png" width="170px" />
            <h3 className="my-3 text-success fw-bold">BUY LATEX MATTRESS ONLINE</h3>
            <h6>Talk to real humans, not robots.</h6>
            <div className="my-4 d-flex callus">
              <Link href="tel:+919458419999">  <button className="callus-1 rounded px-5 py-3 border fw-bold" onClick={handleCall}>CALL US</button> </Link>
              <Link href="mailto:sales@livoncomforts.com"> <button className="callus-2 rounded mx-3 px-5 py-3 p-2 border-0 fw-bold">EMAIL US</button></Link>
              <Link href="tel:+919458419999"><button className="callus-3 rounded px-5 py-3 border-0 fw-bold">LIVE CHAT</button></Link>
            </div>
          </div>
        </div> 
      </div>
    </>
  </div>
  )
}
