import React from 'react'
import '../Css/saptosleep.css'
import './Accountdetails.css';
import { Link } from 'react-router-dom';
import saptosleepone from '../images/sap to sleep 00.1.jpg';
import saptosleepTwo from '../images/sap to sleep 00.2..jpg';
import saptosleepThree from '../images/sap to sleep 00.3 copy.jpg';
import saptosleepFour from '../images/sap to sleep 00.4.jpg';
export default function SapTosleep() {
    const handleCall = () => {
        window.location.href = 'tel:+919458419999';
      };
    return (
        <div>
            <div className="relative banner-sap-to-sleep">
                <div className=" w-full h-full flex justify-center items-center  text-center ">
                    <div className=" items-center  w-3/4 left-10 text-black md:w-1/3 bottom-20 py-6 justify-center p-4 bg-opacity-60 mt-10">
                        <h1 className="sap-to-sleep-heading">JOURNEY OF LATEX</h1>
                        <h3 className="sap-to-sleep-content font-montserrat font-semibold mt-4">How Latex Mattress Is Manufactured At Livon Comforts Mattress Factory?</h3>
                    </div>
                </div>
            </div>
            <div className='contentForLatex container text-center '>
                <div className='whatIsLatex '>
                    <p>But Before that let’s find out what Latex is!</p>
                    <p>Latex is a natural substance derived from the sap of the rubber tree, scientifically known as Hevea brasiliensis. This milky fluid is tapped from the rubber tree and processed to create a versatile material used in a variety of products like mattresses and pillows.</p>
                    <p>Livon partners with Sri Lankan manufacturers to import Dunlop-processed latex foam, utilizing it in the production of mattresses, pillows, and toppers for unparalleled comfort and quality.</p>
                    <div className=' mt-5 d-flex latexImages '>
                        <div >
                            <img src={saptosleepone} alt='saptosleep'/>
                        </div>
                        <div >
                            <img src={saptosleepTwo} alt='saptosleep'/>
                        </div>
                    </div>
                </div>
                <div className='dunlop-heading mb-5'>
                    <h2>JOURNEY OF DUNLOP PROCESSED <span className="moving-line"></span> LATEX MATTRESS</h2>
                    <p>The Dunlop process is a traditional method for producing latex foam, renowned for its simplicity and effectiveness. Here's a glimpse into the key steps involved:</p>
                </div>

            </div>
            <div className='container-fluid mb-5'>
                <div className='row'>
                    <div className='col-lg-6 dunlopImage '>
                        <img src={saptosleepThree} alt='saptosleep'/>
                    </div>
                    <div className='col-lg-6 dunlop-text text-left'>
                        <div className='dunlop-content'>
                            <h3>Latex Extraction</h3>
                            <p> It all begins with the extraction of natural latex sap from rubber trees. This sustainable process involves carefully tapping the trees to collect the raw material, ensuring minimal impact on the environment.</p>
                        </div>
                        <div className='dunlop-content'>
                            <h3>Mixing and Formulation</h3>
                            <p> The collected latex sap undergoes a meticulous mixing process, where it is combined with additives to achieve the desired consistency and properties. This formulation stage is crucial for determining the characteristics of the final latex foam.</p>
                        </div>
                        <div className='dunlop-content'>
                            <h3>Mold Pouring</h3>
                            <p>Once the latex mixture is ready, it is poured into specialized molds in predetermined quantities. These molds can be customized to produce latex foam of various sizes, densities, and firmness levels, catering to diverse consumer preferences. </p>
                        </div>
                        <div className='dunlop-content'>
                            <h3>Heat Curing</h3>
                            <p>The filled molds are then subjected to heat, initiating the vulcanization process. This involves baking the latex foam at controlled temperatures, allowing it to solidify and attain its distinctive cellular structure. The heat curing stage is essential for enhancing the foam's resilience, elasticity, and overall quality. </p>
                        </div>
                        <div className='dunlop-content'>
                            <h3>Washing and Drying</h3>
                            <p>After curing, the latex foam undergoes thorough washing to remove any residual impurities or additives. It is then carefully dried to eliminate excess moisture, ensuring optimal hygiene and longevity.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mb-5'>
                <div className='dunlop-heading mb-5 container text-center'>
                    <h2>Advantages of the <span className="moving-line"></span>Dunlop Process </h2>
                    <p>The Dunlop process offers several advantages that contribute to the exceptional quality of latex foam:</p>
                </div>
                <div className='row container-fluid'>
                    <div className='col-lg-6 dunlop-text advantage-dunlop text-left'>
                        <div className='dunlop-content w-100 mb-5'>
                            <h3>Consistency</h3>
                            <p>The simplicity of the Dunlop process ensures consistency in foam density, firmness, and performance across batches, guaranteeing a uniform sleep experience for consumers.</p>
                            </div> 
                            <div className='dunlop-content w-100 mb-5'>
                            <h3>Durability</h3>
                            <p> Latex foam produced using the Dunlop method is known for its exceptional durability and resilience, maintaining its shape and supportiveness for years to come.</p>
                            </div>
                            <div className='dunlop-content w-100 mb-5'>
                            <h3>Natural Properties</h3>
                            <p>As the Dunlop process primarily utilizes natural latex sap, the resulting foam retains inherent properties such as breathability, hypoallergenicity, and resistance to dust mites and microbes.</p>
                            </div>
                            <div className='dunlop-content w-100'>
                            <h3>Environmentally Friendly</h3>
                            <p>With its reliance on sustainable latex harvesting practices and minimal energy consumption, the Dunlop process aligns with eco-conscious manufacturing principles, making it a greener choice for environment.</p>
                            </div>
                    </div>
                    <div className='col-lg-6 advance-dunlopImage-1 '>
                        <img src={saptosleepFour} alt='saptosleep'/>
                    </div>
                </div>
            </div>
            <div className="container">
        <div className="row">
          <div className="text-center my-5">
            <img src="https://cdn-icons-png.flaticon.com/512/7589/7589521.png" width="170px" alt='saptosleep'/>
            <h3 className="my-3 text-success fw-bold">BUY LATEX MATTRESS ONLINE</h3>
            <h6>Talk to real humans, not robots.</h6>
            <div className="my-4 d-flex callus">
              <Link href="tel:+919458419999">  <button className="callus-1 rounded px-5 py-3 border fw-bold" onClick={handleCall}>CALL US</button> </Link>
              <Link href="mailto:sales@livoncomforts.com"> <button className="callus-2 rounded mx-3 px-5 py-3 p-2 border-0 fw-bold">EMAIL US</button></Link>
              <Link href="tel:+919458419999"><button className="callus-3 rounded px-5 py-3 border-0 fw-bold">LIVE CHAT</button></Link>
            </div>
          </div>
        </div> 
      </div>
        </div>
    )
}
