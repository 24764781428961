import React, { createContext, useContext, useState, useEffect } from 'react';
import baseURL from '../Environment';

// Create a Context
const ImageContext = createContext();

// Function to import all images from a specific directory
const importAllImages = (requireContext) => {
  const images = {};
  requireContext.keys().forEach((item) => {
    const key = item.replace('./', '').replace(/\.[^/.]+$/, ""); // Remove the './' and file extension
    images[key] = requireContext(item); // Store the image in the object
  });
  return images;
};

// Dynamically import all images in the assets/images directory
const images = importAllImages(require.context('../assets', false, /\.(png|jpe?g|svg)$/));

// Create a Provider component
export const ImageProvider = ({ children }) => {
  const [products, setProducts] = useState([]); // Store products with IDs and images

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(baseURL+'productdetails');
        const data = await response.json();
        
        const productDetails = data.flatMap(product => 
          product.products.map(p => {
            const id = p.id; // Assuming 'id' is the product ID
            // Construct local image URLs using the ID (take only the first image for simplicity)
            const localImage = images[`${id}1`]; // Get the first image for the product
            
            return {
              id: id, 
              imageUrl: localImage // Set local image instead of API URL
            };
          })
        );
        
        setProducts(productDetails);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    
    fetchImages();
  }, []);

  // Log the products whenever they change
  useEffect(() => {
    // console.log('Products updated:', products);
  }, [products]);

  return (
    <ImageContext.Provider value={products}>
      {children}
    </ImageContext.Provider>
  );
};

// Create a custom hook for easy access to the context
export const useImageContext = () => {
  return useContext(ImageContext);
};