import React,{useEffect,useState} from 'react';
import './Accountdetails.css';
import { Link, Route, Routes } from 'react-router-dom';
import ProductInformation from './ProductInformation';
import OrdersPage from './OrdersPage';
import ManageaddresPage from './ManageaddresPage';
import ProfilePage from './ProfilePage';
import useUserId from './UseUserId'; // Import the custom hook
import baseURL from '../Environment';
const AccountDetails = () => {
  // The user ID obtained from the useUserId()
  const userId = useUserId();

  // State to store form data
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phoneNumber: ''
  });

  // Function to get the time of day
  const getTimeOfDay = () => {
    const time = new Date().getHours();
    let greeting = "";
    if (time < 13) {
      greeting = "Good Morning";
    } else if (time < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    return greeting;
  };

  // Function to handle the call action
  const handleCall = () => {
    window.location.href = 'tel:+919458419999';
  };

  // Function to handle the logout action
  const handleLogout = async () => {
    try {
      localStorage.removeItem('token');
      const response = await fetch(baseURL + `profile/logout/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // You can include a request body if required
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error('Logout request failed');
      }
      // Handle successful logout (e.g., clear local storage, redirect, etc.)
      window.location.href = '/';
    } catch (error) {
      // Handle error
      console.error('Logout failed', error);
    }
  };


    // Function to fetch user details from the server
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(baseURL + `profile/user-details/${userId}`, {
          method: 'GET',
        });
  
        const data = await response.json();
        if (response.ok) {
          // Update form data with user details fetched from the server
          setFormData(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
  
    // useEffect to fetch user details when the component mounts or userId changes
    useEffect(() => {
      if (userId !== null) {
        fetchUserDetails();
      }
    }, [userId]);

    
  return (
    <>
      <div className="Account-details">
        <div className="row1 Account-details_rows">
          <h2 className='weelcome'> Hi, {formData.fullname}
          </h2>
          <p style={{ color: 'rgb(60 86 87)' }}>{getTimeOfDay()}</p>
          <hr />
          <div className="order-details">
            <div className="details">
              <div className='order'>
                <Link to='/account-detail' ><h4 className="sub-order ml-8 text-sm font-montserrat text-mainColour font-semibold" style={{ fontSize: '14px' }}>ORDERS</h4></Link>
              </div>
              <div className='profile'>
                <Link to='/account-detail/profile-page'> <h4 className="sub-profile ml-8 text-sm font-montserrat text-mainColour font-semibold" style={{ fontSize: '14px' }}>PROFILE</h4> </Link>
              </div>
              <div className='manage-address'>
                <Link to='/account-detail/address-page'> <h4 className="sub-manageaddress eml-8 text-sm font-montserrat text-mainColour font-semibold" style={{ fontSize: '14px' }}>MANAGE ADDRESSES</h4> </Link>
              </div>
              <div className='logout'>
                <h4 className="sub-logout ml-8 text-sm font-montserrat text-mainColour font-semibold" style={{ fontSize: '14px' }}  onClick={handleLogout}>LOGOUT</h4>
              </div>
            </div>
          </div>
        </div>
        <div className='row2'>
          <Routes>
            <Route path='/' element={<OrdersPage />} />
            <Route path='/profile-page' element={<ProfilePage />} />
            <Route path='/address-page' element={<ManageaddresPage />} />
            <Route path='/product/:orderId' element={<ProductInformation />} />
          </Routes>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="text-center my-5">
            <img src="https://cdn-icons-png.flaticon.com/512/7589/7589521.png" width="170px" alt='customerCare'/>
            <h3 className="my-3 text-success fw-bold">BUY LATEX MATTRESS ONLINE</h3>
            <h6>Talk to real humans, not robots.</h6>
            <div className="my-4 d-flex callus">
              <Link href="tel:+919458419999">  <button className="callus-1 rounded px-5 py-3 border fw-bold" onClick={handleCall}>CALL US</button> </Link>
              <Link href="mailto:sales@livoncomforts.com"> <button className="callus-2 rounded mx-3 px-5 py-3 p-2 border-0 fw-bold">EMAIL US</button></Link>
              <Link href="tel:+919458419999"><button className="callus-3 rounded px-5 py-3 border-0 fw-bold">LIVE CHAT</button></Link>
            </div>
          </div>
        </div> 
      </div>
    </>
  );
};

export default AccountDetails;
