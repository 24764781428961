import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Header.css';
import baseURL from '../Environment';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Step 1: Import all images locally
const importAllImages = (requireContext) => {
  const images = {};
  requireContext.keys().forEach((item) => {
    const key = item.replace('./', '').replace(/\.[^/.]+$/, ""); // Remove the './' and file extension
    images[key] = requireContext(item); // Store the image in the object
  });
  return images;
};

const images = importAllImages(require.context('../assets', false, /\.(png|jpe?g|svg)$/));

export default function PillowProducts() {
  useEffect(() => {
    AOS.init({
        duration: 1000,
    });
  }, []);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1440 }, items: 4 },
    laptop:{breakpoint: { max: 1440, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 767 }, items: 2 },
    mobile: { breakpoint: { max: 767, min: 0 }, items: 1 }
  };

  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetchInfo = () => {
    return fetch(baseURL + 'productdetails/pillow')
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToSingleProductDetails = (data) => {
    const titleWithHyphens = data.title.replace(/\s+/g, '-');
    navigate(`/product/${data.category}/${data.id}/${titleWithHyphens}`);
  };

  // Function to get local image for a product based on its ID
  const getLocalImage = (productId) => {
    return images[`${productId}1`]; // Assuming local images are named as id1.jpg
  };

  return (
   <div className='pillow_products' id='pillow-products'>
    <div className="section">
      <div className='container-fluid'>
        <div className='mat-heading pillow_heading' >
          <h2> Our Pillows Range</h2>
          <div className='pillow-products_para'>
          <p>Made from 100% natural and GOTS certified organic latex, our organic latex pillows are exceptionally comfortable and breathable. They are carbon neutralized for an eco-friendly choice.</p>
          </div>
        </div>
        <Carousel responsive={responsive}>
          {data.map((dataObj) => (
            <div className='pcard p-card3 product' key={dataObj.id} onClick={() => { navigateToSingleProductDetails(dataObj) }}>
              {/* Use local image instead of API image */}
              <img src={getLocalImage(dataObj.id)} alt='pillow product' className='product-image' />
              <Card.Body>
                <div className="desc-gap">
                  <Card.Title className='pillow-color'>{dataObj.title}</Card.Title>
                  {dataObj.descriptions && dataObj.descriptions.length > 0 ? (
                    <ul>
                      {dataObj.descriptions.slice(0, 2).map((description, index) => (
                        <li key={index} className='pillow-description'>{description}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No description available.</p>
                  )}
                </div>
                <Button className='pillow-button' onClick={() => { navigateToSingleProductDetails(dataObj) }}>Buy Now</Button>
              </Card.Body>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
   </div>
  )
}
