import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import './Manageaddress.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Row, Card, Button } from 'react-bootstrap';
import useUserId from './UseUserId'; // Import the custom hook
import baseURL from '../Environment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify'; // Import the Bounce transition effect
const ManageaddresPage = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [data, setData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [form, setForm] = useState({
    fullname: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    email: '',
    phoneNumber: '',
  });
  const [editMode, setEditMode] = useState(false); // To track if editing
  const [selectedAddressId, setSelectedAddressId] = useState(null); // To track the selected address ID for editing
  const userId = useUserId();

  const toggleDisplay = () => {
    setIsDisplayed(!isDisplayed);
    setIsExpanded(!isExpanded);
  };
  //Get all Addresses
  const getapi = () => {
    if (userId) {
      fetch(baseURL + `addresses/${userId}/all-addresses`)
        .then((Response) => {
          if (!Response.ok) {
            throw new Error('Failed to fetch addresses');
          }
          return Response.json();
        })
        .then((result) => setData(result))
        .catch((error) => console.error('Error fetching addresses:', error));
    }
  };

  useEffect(() => {
    getapi();
  }, [userId]);

  //To Add and update address
  const formsubmit = async (e) => {
    e.preventDefault();
    const request = {
      method: 'POST', // Always use POST
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(form),
    };
    const url = `${baseURL}addresses/${userId}/${selectedAddressId || ''}`; // Use selectedAddressId if it exists, else empty string
    try {
      const response = await fetch(url, request);
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
      const result = await response.json();
      // Show a success toast notification
      toast.success(selectedAddressId ? 'Address Updated!' : 'Address Added!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
        style: {
          minHeight: '30px', 
        },
      });

      // Reset the form
      setForm({
        fullname: '',
        address: '',
        city: '',
        state: '',
        pincode: '',
        email: '',
        phoneNumber: '',
      });
      setSelectedAddressId(null);
      getapi(); // Refresh or update the address list
      setIsDisplayed(!isDisplayed);
      setIsExpanded(!isExpanded);

    } catch (error) {
      // Show an error toast notification
      toast.error('Error submitting form. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
        style: {
          minHeight: '30px', 
        },
      });
    }
  };

  const collectinput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleEdit = (addressItem) => {
    setForm({ ...addressItem.deliveryAddress });
    setEditMode(true);
    setSelectedAddressId(addressItem.deliveryAddress._id); // Using deliveryAddress._id
    setIsDisplayed(true);
    setIsExpanded(!isExpanded);
  };

  const handleDelete = async (addressId) => {
    const url = `${baseURL}addresses/${userId}/address/${addressId}`;

    try {
      const response = await fetch(url, { method: 'DELETE' });

      if (response.ok) {
        // Show a success toast notification
        toast.success('Address Deleted!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
          style: {
            minHeight: '30px', 
          },
        });
        getapi(); // Refresh or update the address list
      } else {
        // Show an error toast notification
        toast.error('Failed to delete address', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
          style: {
            minHeight: '30px', 
          },
        });
      }
    } catch (error) {
      // Show an error toast notification for network errors
      toast.error('Error deleting address. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
        style: {
          minHeight: '30px', 
        },
      });
      console.error('Error deleting address:', error);
    }
  };

  if (data == null) {
    return <p>Loading your addresses... Please wait.</p>; // More informative loading message
  }

  return (
    <>
      <div>
        <div className='mt-4 mb-5 manage_address'>
          <h2 className='personal-info'>Manage Addresses</h2>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Bounce}
        />
        <div className="address-cards ">
          {data.addresses && data.addresses.length > 0 ? (
            <Row>
              {data.addresses.map((addressItem, index) => (
                <Col md={4} key={index} className='mb-3'>
                  <Card className='address-card'>
                    <Card.Body>
                      <div className="card-icons">
                        <Button variant="link" onClick={() => handleEdit(addressItem)}>
                          <FontAwesomeIcon icon={faEdit} className="edit-icon" />
                        </Button>
                        <Button variant="link" onClick={() => handleDelete(addressItem._id)}>
                          <FontAwesomeIcon icon={faTrash} className="delete-icon" />
                        </Button>
                      </div>
                      {/* Display address details */}
                      <Card.Title className="fw-bolder">{addressItem.deliveryAddress.fullname}</Card.Title>
                      <Card.Text style={{ fontWeight: '500' }}>                  
                        {addressItem.deliveryAddress.address},<br /> 
                         {addressItem.deliveryAddress.city}, {addressItem.deliveryAddress.state} - {addressItem.deliveryAddress.pincode}
                        <p>India</p>
                        <p>Phone number: {addressItem.deliveryAddress.phoneNumber}</p>
                        <p>Email: {addressItem.deliveryAddress.email}</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}

            </Row>
          ) : (
            <p>No addresses found. Please add a new address.</p>
          )}
        </div>
        <div className='add-address'>
          <div className={`post-address ${isExpanded ? 'expanded' : ''}`} >
            <p className='display-1 plus mx-3 my-2' >
              <FontAwesomeIcon icon={faPlus}  onClick={toggleDisplay}/>
            </p>
            <p>{editMode ? 'Edit Address' : 'Add a new address'}</p>
            <div className='new-address' id="referral" style={{ display: isDisplayed ? 'block' : 'none' }}>
              <Form className='my-3'  onSubmit={formsubmit}>
                <Row>
                  <Form.Group as={Col} md="4" controlId="validationCustom01" className='w-50 my-2' >
                    <Form.Control
                      type="text"
                      placeholder="Full Name"
                      name="fullname"
                      value={form.fullname}
                      onChange={collectinput}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom02" className='w-50 my-2'>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={form.email}
                      onChange={collectinput}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom02" className="w-100 my-2">
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={form.phoneNumber.startsWith('+91') ? form.phoneNumber : '+91' + form.phoneNumber}
                      onChange={(e) => {
                        // Ensure the country code is not removed
                        if (!e.target.value.startsWith('+91')) {
                          collectinput({
                            target: {
                              name: 'phoneNumber',
                              value: '+91' + e.target.value.replace('+91', ''),
                            },
                          });
                        } else {
                          collectinput(e);
                        }
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom01" className='full-address w-100'>
                    <textarea
                      className='field-location textarea-addres w-100 p-2' rows='5'
                      type="text"
                      placeholder="Full Address"
                      name="address"
                      value={form.address}
                      onChange={collectinput}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationCustom02" className='w-100'>
                    <Form.Control
                      type="text"
                      placeholder="Enter City"
                      name="city"
                      value={form.city}
                      onChange={collectinput}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationCustom01" className='w-50 my-2'>
                    <Form.Select
                      type="text"
                      name="state"
                      value={form.state}
                      onChange={collectinput}
                      required
                      aria-label="Default select example">
                      <option value="" disabled>Select State</option>
                      <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationCustom01" className='w-50 my-2'>
                    <Form.Control
                      type="text"
                      placeholder="Pin code"
                      name="pincode"
                      value={form.pincode}
                      onChange={collectinput}
                      required
                    />
                  </Form.Group>
                </Row>
                <div className="text-center my-2 ">
                  <button className="btn btn-outline-success p-2 ms-2 mt-5" >{editMode ? 'Update Address' : 'Add Address'}</button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageaddresPage;
