import React, { useEffect } from "react";
import './Header.css';
import BannerShape from './BannerShape';
import Carousel from 'react-bootstrap/Carousel';
import whyBuy from '../images/coursual 3.jpg';
import Indeximage from '../images/coursual 1.jpg';
import Indeximage2 from '../images/coursual 4.jpg';
import Indeximages3 from '../images/BlobBed.png';
import RestHealthy from '../images/Rest healthy logo png.png';
import RestCozy from '../images/LIVON COZY LOGO copy.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Col, Container, Row } from "react-bootstrap";

function HeroImage() {

  // Initialize AOS animation library
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation in milliseconds
    });
  }, []);

  return (
    <div className="hero-carousel">
      <Carousel data-bs-theme="dark">
        <Carousel.Item className="hero-first">
          <div className="IndexImage tree-image">
            <img className="d-block w-100" src={whyBuy} alt="First slide" />
          </div>
          <Carousel.Caption data-aos="zoom-in">
            <div className="container">
              <div className="content-overlay">
                <h2>Welcome to Livon Rest Healthy</h2>
                <h3>Product range for your healthy and comfortable sleep.</h3>
                <p>Mattresses, pillows, toppers, and accessories in the Rest Healthy range are made out of 100% pure, natural, certified organic materials like latex, cotton, bamboo, and other naturally grown materials that are natural, eco-friendly, and fully biodegradable.</p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="bubble-section">
          <div className=" row">
            <div className="content-container col-md-6 col-12 d-flex justify-content-center align-items-center">
              <div className="content-overlay2 text-center d-none d-md-block">
                <h2>Welcome to Livon Rest Healthy</h2>
                <h3>Product range for your healthy and comfortable sleep.</h3>
                <p className="d-none d-lg-block">Mattresses, pillows, toppers, and accessories in the Rest Healthy range are made out of 100% pure, natural, certified organic materials like latex, cotton, bamboo, and other naturally grown materials that are natural, eco-friendly, and fully biodegradable.</p>
                <Container>
                  <Row className="rest-logos d-flex justify-content-center align-items-center">
                    <Col lg={6}>
                      <img className="d-block w-100" src={RestHealthy} alt="Second slide" />
                    </Col>
                    <Col lg={6}>
                      <img className="d-block w-100" src={RestCozy} alt="Second slide" />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="image-container col-md-6 justify-content-center align-items-center">
              <BannerShape />
              <img className="d-block mattress-image" src={Indeximages3} alt="Fourth slide" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="IndexImage">
            <img className="d-block w-100" src={Indeximage} alt="Second slide" />
          </div>
          <Carousel.Caption >
            <div className="container">
              <div className="content-overlay">
                <h2>Welcome to Livon Rest Healthy</h2>
                <h3>Product range for your healthy and comfortable sleep.</h3>
                <p>Mattresses, pillows, toppers, and accessories in the Rest Healthy range are made out of 100% pure, natural, certified organic materials like latex, cotton, bamboo, and other naturally grown materials that are natural, eco-friendly, and fully biodegradable.</p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="IndexImage">
            <img className="d-block w-100" src={Indeximage2} alt="Third slide" />
          </div>
          <Carousel.Caption>
            <div className="container">
              <div className="content-overlay">
                <h2>Welcome to Livon Rest Healthy</h2>
                <h3>Product range for your healthy and comfortable sleep.</h3>
                <p>Mattresses, pillows, toppers, and accessories in the Rest Healthy range are made out of 100% pure, natural, certified organic materials like latex, cotton, bamboo, and other naturally grown materials that are natural, eco-friendly, and fully biodegradable.</p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

      </Carousel>
    </div>
  );
}

export default HeroImage;
